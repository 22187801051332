import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@/components/ui/command";
import { Button } from "@/components/ui/button";
import {
  HiHome,
  HiShoppingCart,
  HiCreditCard,
  HiOutlineExternalLink,
} from "react-icons/hi";
import { twMerge } from "tailwind-merge";

export default function SideBar() {
  const navigate = useNavigate();
  const [isActive, setActive] = useState(true);

  return (
    <div className={twMerge("sidebar", isActive && "active")}>
      <ScrollArea className="rounded-md scroll-sidebar">
        <div className="p-4">
          <Command className="rounded-lg border shadow-md">
            <CommandInput placeholder="Type a command or search..." />
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup heading="Menu">
                <CommandItem className="py-1">
                  <Button
                    className="btnin-menu"
                    onClick={() => navigate("/home")}
                  >
                    <HiHome className="mr-2 h-4 w-4" />
                    <span>Beranda</span>
                  </Button>
                </CommandItem>
                <CommandItem className="py-1">
                  <Button
                    className="btnin-menu"
                    onClick={() => navigate("/order")}
                  >
                    <HiShoppingCart className="mr-2 h-4 w-4" />
                    <span>Order</span>
                  </Button>
                </CommandItem>
                <CommandItem className="py-1">
                  <Button
                    className="btnin-menu"
                    onClick={() =>
                      window.open(
                        "https://finance.cekgudang.com/login",
                        "_blank"
                      )
                    }
                  >
                    <HiOutlineExternalLink className="mr-2 h-4 w-4" />
                    <span>Apps</span>
                  </Button>
                </CommandItem>
              </CommandGroup>
              <CommandSeparator />
              <CommandGroup heading="Tagihan">
                <CommandItem className="py-1">
                  <Button
                    className="btnin-menu"
                    onClick={() => navigate("/billing")}
                  >
                    <HiCreditCard className="mr-2 h-4 w-4" />
                    <span>Billing</span>
                  </Button>
                </CommandItem>
              </CommandGroup>
            </CommandList>
          </Command>
        </div>
      </ScrollArea>
    </div>
  );
}
