import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import { HiHome } from "react-icons/hi";
import { twMerge } from "tailwind-merge";
import Header from "@/components/Header";
import SideBar from "@/components/Sidebar";

export default function ChangePassword() {
  const navigate = useNavigate();
  const [isActive, setActive] = useState(true);
  const handleSidebar = () => {
    setActive(!isActive);
  };
  const invoices = [
    {
      invoice: "INV001",
      paymentStatus: "Paid",
      totalAmount: "$250.00",
      paymentMethod: "Credit Card",
    },
    {
      invoice: "INV002",
      paymentStatus: "Pending",
      totalAmount: "$150.00",
      paymentMethod: "PayPal",
    },
    {
      invoice: "INV003",
      paymentStatus: "Unpaid",
      totalAmount: "$350.00",
      paymentMethod: "Bank Transfer",
    },
    {
      invoice: "INV004",
      paymentStatus: "Paid",
      totalAmount: "$450.00",
      paymentMethod: "Credit Card",
    },
    {
      invoice: "INV005",
      paymentStatus: "Paid",
      totalAmount: "$550.00",
      paymentMethod: "PayPal",
    },
    {
      invoice: "INV006",
      paymentStatus: "Pending",
      totalAmount: "$200.00",
      paymentMethod: "Bank Transfer",
    },
    {
      invoice: "INV007",
      paymentStatus: "Unpaid",
      totalAmount: "$300.00",
      paymentMethod: "Credit Card",
    },
  ];

  return (
    <div className="layout">
      <Header />
      <div className="flex-1 h-[900px] relative">
        <SideBar />
        <div className={twMerge("content-in", isActive && "active")}>
          <ScrollArea className="rounded-md scroll-sidebar">
            <div className="p-0">
              <div className="h-auto">
                <Label className="text-2xl font-bold title-main">Billing</Label>
                <Breadcrumb>
                  <BreadcrumbList>
                    <BreadcrumbItem>
                      <BreadcrumbLink
                        href="/home"
                        className="flex gap-1 items-center"
                      >
                        <HiHome></HiHome> Beranda
                      </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />
                    <BreadcrumbItem>
                      <BreadcrumbPage>Billing</BreadcrumbPage>
                    </BreadcrumbItem>
                  </BreadcrumbList>
                </Breadcrumb>
              </div>
              <form>
                <div className="h-auto pt-4">
                  <div className="grid grid-cols-12 gap-5">
                    <div className="col-span-12 h-full">
                      <Card className="w-full h-full pt-5">
                        <CardContent>
                          <Table>
                            <TableHeader>
                              <TableRow>
                                <TableHead className="w-[100px]">
                                  Invoice
                                </TableHead>
                                <TableHead>Status</TableHead>
                                <TableHead>Method</TableHead>
                                <TableHead className="text-right">
                                  Amount
                                </TableHead>
                              </TableRow>
                            </TableHeader>
                            <TableBody>
                              {invoices.map((invoice) => (
                                <TableRow key={invoice.invoice}>
                                  <TableCell className="font-medium">
                                    {invoice.invoice}
                                  </TableCell>
                                  <TableCell>{invoice.paymentStatus}</TableCell>
                                  <TableCell>{invoice.paymentMethod}</TableCell>
                                  <TableCell className="text-right">
                                    {invoice.totalAmount}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                            <TableFooter>
                              <TableRow>
                                <TableCell colSpan={3}>TOTAL</TableCell>
                                <TableCell className="text-right">
                                  $2,500.00
                                </TableCell>
                              </TableRow>
                            </TableFooter>
                          </Table>
                        </CardContent>
                        <CardFooter className="flex"></CardFooter>
                      </Card>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </ScrollArea>
        </div>
      </div>
    </div>
  );
}
