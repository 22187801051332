import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Label } from "@/components/ui/label";
import { ScrollArea } from "@/components/ui/scroll-area";
import Header from "@/components/Header";
import { AlertCircle } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { API } from "../../helpers";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
} from "@/components/ui/card";
import {
  HiHome,
  HiCheckCircle,
  HiX,
  HiDocumentDuplicate,
} from "react-icons/hi";
import { twMerge } from "tailwind-merge";
import SideBar from "../../components/Sidebar";
import Swal from "sweetalert2";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  styled,
  Typography,
} from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

async function paymentOrder(credentials) {
  const accessToken = localStorage.getItem("accessToken");

  return fetch(`${API}/order`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
}

export default function Order() {
  const navigate = useNavigate();
  const [isActive, setActive] = useState(true);
  const handleSidebar = () => {
    setActive(!isActive);
  };
  const user = JSON.parse(localStorage.getItem("user"));
  const [dataPackage, setDataPackage] = useState([]);
  const [dataPaymentMethod, setDataPaymentMethod] = useState([]);
  const [dataSubscribe, setDataSubscribe] = useState("");
  const [dataPM, setDataPM] = useState("");
  const accessToken = localStorage.getItem("accessToken");
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [dataVA, setDataVA] = useState("");
  const [dataTotal, setDataTotal] = useState(0);
  const [dataPMName, setDataPMName] = useState("");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  useEffect(() => {
    fetch(`${API}/params/package`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setDataPackage(data.data);
      });

    fetch(`${API}/payment/payment-method`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setDataPaymentMethod(data.data);
      });
  }, []);

  const handleOpenModal = () => {
    if (dataSubscribe == "") {
      Swal.fire({
        title: "Warning!",
        text: "Silahkan pilih langganan, terlebih dahulu!!",
        icon: "warning",
      });
    } else {
      setOpenModal(true);
    }
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCloseModal2 = () => {
    setOpenModal2(false);
  };

  const handleNextPayment = async (e) => {
    e.preventDefault();
    handleCloseModal();
    if (dataPM == "") {
      Swal.fire({
        title: "Warning!",
        text: "Silahkan pilih metode pembayaran, terlebih dahulu!!",
        icon: "warning",
        buttons: false,
        timer: 2000,
      }).then((value) => {
        handleOpenModal();
      });
    } else {
      handleCloseModal();

      const arrPayment = dataPM.split("||");

      const response = await paymentOrder({
        payment_method: arrPayment[0],
        package: dataSubscribe,
      });
      if (response.code == 200) {
        setDataVA(response.data.trx_id);
        setDataTotal(response.data.total);
        setDataPMName(arrPayment[1]);
        setOpenModal2(true);
      } else {
        Swal.fire({
          title: "Error!",
          text: response.message,
          icon: "error",
          buttons: false,
          timer: 2000,
        }).then((value) => {
          handleOpenModal();
        });
      }
    }
  };

  const handleSnackbarClick = () => {
    setOpenSnackbar(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <HiX fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="layout">
      <Header />
      <div className="flex-1 h-[900px] relative">
        <SideBar />
        <div className={twMerge("content-in", isActive && "active")}>
          <ScrollArea className="rounded-md scroll-sidebar">
            <div className="p-0">
              <div className="h-auto">
                <Label className="text-2xl font-bold title-main">Order</Label>
                <Breadcrumb>
                  <BreadcrumbList>
                    <BreadcrumbItem>
                      <BreadcrumbLink
                        href="/home"
                        className="flex gap-1 items-center"
                      >
                        <HiHome></HiHome> Beranda
                      </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />
                    <BreadcrumbItem>
                      <BreadcrumbPage>Order</BreadcrumbPage>
                    </BreadcrumbItem>
                  </BreadcrumbList>
                </Breadcrumb>
              </div>
              <div className="h-auto pt-4">
                <div className="grid grid-cols-3 gap-5">
                  {user.company_id <= 0 && (
                    <div className="col-span-3 px-3">
                      <Alert variant="destructive">
                        <AlertCircle className="h-4 w-4" />
                        <AlertTitle>Perhatian!</AlertTitle>
                        <AlertDescription>
                          Anda belum mengisi profil perusahaan, silahkan isi
                          profil perusahaan terlebih dahulu.{" "}
                          <Button
                            className="bg-[var(--color-primary)]"
                            onClick={() => navigate("/profile")}
                            size="sm"
                          >
                            Isi disini
                          </Button>
                        </AlertDescription>
                      </Alert>
                    </div>
                  )}
                  <div className="h-full col-span-1">
                    {dataPackage.map((pack) => (
                      <Card>
                        <CardHeader>
                          <CardTitle>{pack.name}</CardTitle>
                        </CardHeader>
                        <CardContent className="space-y-2">
                          <div className="grid grid-cols-8 gap-2 pb-6">
                            <div className="flex justify-center justify-items-center">
                              <HiCheckCircle className="text-[#7380EC] size-5"></HiCheckCircle>
                            </div>
                            <div className="col-span-7">Saldo Awal</div>
                            <div className="flex justify-center justify-items-center">
                              <HiCheckCircle className="text-[#7380EC] size-5"></HiCheckCircle>
                            </div>
                            <div className="col-span-7">Pemasukan</div>
                            <div className="flex justify-center justify-items-center">
                              <HiCheckCircle className="text-[#7380EC] size-5"></HiCheckCircle>
                            </div>
                            <div className="col-span-7">Pengeluaran</div>
                            <div className="flex justify-center justify-items-center">
                              <HiCheckCircle className="text-[#7380EC] size-5"></HiCheckCircle>
                            </div>
                            <div className="col-span-7">Jurnal Umum</div>
                            <div className="flex justify-center justify-items-center">
                              <HiCheckCircle className="text-[#7380EC] size-5"></HiCheckCircle>
                            </div>
                            <div className="col-span-7">Migrasi Data</div>
                            <div className="flex justify-center justify-items-center">
                              <HiCheckCircle className="text-[#7380EC] size-5"></HiCheckCircle>
                            </div>
                            <div className="col-span-7">Arus Kas</div>
                            <div className="flex justify-center justify-items-center">
                              <HiCheckCircle className="text-[#7380EC] size-5"></HiCheckCircle>
                            </div>
                            <div className="col-span-7">Buku Besar</div>
                            <div className="flex justify-center justify-items-center">
                              <HiCheckCircle className="text-[#7380EC] size-5"></HiCheckCircle>
                            </div>
                            <div className="col-span-7">Laba Rugi</div>
                            <div className="flex justify-center justify-items-center">
                              <HiCheckCircle className="text-[#7380EC] size-5"></HiCheckCircle>
                            </div>
                            <div className="col-span-7">Neraca</div>
                            <div className="flex justify-center justify-items-center">
                              <HiCheckCircle className="text-[#7380EC] size-5"></HiCheckCircle>
                            </div>
                            <div className="col-span-7">Kategori COA</div>
                            <div className="flex justify-center justify-items-center">
                              <HiCheckCircle className="text-[#7380EC] size-5"></HiCheckCircle>
                            </div>
                            <div className="col-span-7">COA</div>
                            <div className="flex justify-center justify-items-center">
                              <HiCheckCircle className="text-[#7380EC] size-5"></HiCheckCircle>
                            </div>
                            <div className="col-span-7">1 User</div>
                          </div>
                          <CardTitle>Langganan</CardTitle>
                          <div className="space-y-1">
                            {pack.choice.map((choice) => (
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  value={choice.id}
                                  name="subscribe"
                                  onChange={(e) =>
                                    setDataSubscribe(e.target.value)
                                  }
                                />
                                <label className="form-check-label text-sm ml-2 font-medium">
                                  Langganan {choice.name} Rp.{" "}
                                  {choice.price_month}/bulan
                                </label>
                              </div>
                            ))}
                          </div>
                        </CardContent>
                        <CardFooter>
                          <Button
                            className="bg-[var(--color-primary)]"
                            disabled={user.company_id <= 0 ? true : false}
                            onClick={handleOpenModal}
                          >
                            Lanjut Pembayaran
                          </Button>
                          <BootstrapDialog
                            onClose={handleCloseModal}
                            aria-labelledby="customized-dialog-title"
                            open={openModal}
                          >
                            <DialogTitle
                              sx={{ m: 0, p: 2 }}
                              id="customized-dialog-title"
                              style={{
                                textAlign: "center",
                              }}
                            >
                              Informasi Pembayaran Langganan
                            </DialogTitle>
                            <IconButton
                              aria-label="close"
                              onClick={handleCloseModal}
                              sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                              }}
                            >
                              <HiX />
                            </IconButton>
                            <DialogContent dividers>
                              <Typography
                                gutterBottom
                                style={{ textAlign: "center" }}
                              >
                                Silahkan memilih Bank pembayaran dibawah ini.
                              </Typography>
                              <Typography
                                gutterBottom
                                style={{ textAlign: "center" }}
                              >
                                Transfer pembayaran anda melalui salah satu Bank
                                dibawah ini, jangan lupa melakukan konfirmasi
                                pembayaran kepada customer service kami setelah
                                mengirimkan dana. Jika ada error, Pastikan untuk
                                screenshot error dan beritahu customer service
                                kami.
                              </Typography>
                              <Typography gutterBottom>
                                <div className="grid gap-4 py-4">
                                  <div className="grid grid-cols-5 gap-4">
                                    {dataPaymentMethod.map((payment) => (
                                      <div className="form-check">
                                        <input
                                          type="radio"
                                          className="form-check-input"
                                          value={
                                            payment.id + "||" + payment.name
                                          }
                                          name="payment"
                                          onChange={(e) =>
                                            setDataPM(e.target.value)
                                          }
                                        />
                                        <label className="form-check-label text-sm ml-2 font-medium">
                                          {payment.name}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </Typography>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                className="bg-[var(--color-primary)]"
                                onClick={handleNextPayment}
                              >
                                Lanjut Pembayaran
                              </Button>
                            </DialogActions>
                          </BootstrapDialog>

                          <BootstrapDialog
                            onClose={handleCloseModal2}
                            aria-labelledby="customized-dialog-title"
                            open={openModal2}
                          >
                            <DialogTitle
                              sx={{ m: 0, p: 2 }}
                              id="customized-dialog-title"
                            >
                              Detil Pembayaran
                              <Badge className="text-center items-center w-[90%] float-start bg-[#FFB547]">
                                Menunggu Pembayaran
                              </Badge>
                            </DialogTitle>
                            <IconButton
                              aria-label="close"
                              onClick={handleCloseModal2}
                              sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                              }}
                            >
                              <HiX />
                            </IconButton>
                            <DialogContent dividers>
                              <div className="grid gap-4 py-4">
                                <div className="items-center gap-4">
                                  <Label className="text-right">
                                    <b>Pembayaran VIA :</b>
                                    <br></br>
                                    {dataPMName}
                                  </Label>
                                </div>
                                <div className="items-center gap-4">
                                  <Label className="text-right">
                                    <b>Nomor Virtual Account :</b>
                                    <br></br>
                                    {dataVA}
                                    <Button
                                      variant="outline"
                                      size="icon"
                                      onClick={() => {
                                        navigator.clipboard.writeText(dataVA);
                                        handleSnackbarClick();
                                      }}
                                    >
                                      <HiDocumentDuplicate className="h-5 w-5" />
                                    </Button>
                                  </Label>
                                </div>
                                <div className="items-center gap-4">
                                  <Label className="text-right">
                                    <b>Total Rincian :</b>
                                    <br></br>
                                    Total : {dataTotal}
                                  </Label>
                                </div>
                              </div>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                className="bg-[var(--color-primary)]"
                                onClick={handleCloseModal2}
                              >
                                Selesai
                              </Button>
                            </DialogActions>
                          </BootstrapDialog>

                          <Snackbar
                            open={openSnackbar}
                            autoHideDuration={6000}
                            onClose={handleSnackbarClose}
                            message="Copy to clipboard"
                            action={action}
                          />
                        </CardFooter>
                      </Card>
                    ))}
                  </div>
                  <div className="h-full">
                    <Card>
                      <CardHeader>
                        <CardTitle>CG Finance Ultimate</CardTitle>
                      </CardHeader>
                      <CardContent className="space-y-2">
                        <div className="grid grid-cols-8 gap-2 pb-6">
                          <div className="flex justify-center justify-items-center">
                            <HiCheckCircle className="text-[#7380EC] size-5"></HiCheckCircle>
                          </div>
                          <div className="col-span-7">Saldo Awal</div>
                          <div className="flex justify-center justify-items-center">
                            <HiCheckCircle className="text-[#7380EC] size-5"></HiCheckCircle>
                          </div>
                          <div className="col-span-7">Pemasukan</div>
                          <div className="flex justify-center justify-items-center">
                            <HiCheckCircle className="text-[#7380EC] size-5"></HiCheckCircle>
                          </div>
                          <div className="col-span-7">Pengeluaran</div>
                          <div className="flex justify-center justify-items-center">
                            <HiCheckCircle className="text-[#7380EC] size-5"></HiCheckCircle>
                          </div>
                          <div className="col-span-7">Jurnal Umum</div>
                          <div className="flex justify-center justify-items-center">
                            <HiCheckCircle className="text-[#7380EC] size-5"></HiCheckCircle>
                          </div>
                          <div className="col-span-7">Migrasi Data</div>
                          <div className="flex justify-center justify-items-center">
                            <HiCheckCircle className="text-[#7380EC] size-5"></HiCheckCircle>
                          </div>
                          <div className="col-span-7">Arus Kas</div>
                          <div className="flex justify-center justify-items-center">
                            <HiCheckCircle className="text-[#7380EC] size-5"></HiCheckCircle>
                          </div>
                          <div className="col-span-7">Buku Besar</div>
                          <div className="flex justify-center justify-items-center">
                            <HiCheckCircle className="text-[#7380EC] size-5"></HiCheckCircle>
                          </div>
                          <div className="col-span-7">Laba Rugi</div>
                          <div className="flex justify-center justify-items-center">
                            <HiCheckCircle className="text-[#7380EC] size-5"></HiCheckCircle>
                          </div>
                          <div className="col-span-7">Neraca</div>
                          <div className="flex justify-center justify-items-center">
                            <HiCheckCircle className="text-[#7380EC] size-5"></HiCheckCircle>
                          </div>
                          <div className="col-span-7">Kategori COA</div>
                          <div className="flex justify-center justify-items-center">
                            <HiCheckCircle className="text-[#7380EC] size-5"></HiCheckCircle>
                          </div>
                          <div className="col-span-7">COA</div>
                          <div className="flex justify-center justify-items-center">
                            <HiCheckCircle className="text-[#7380EC] size-5"></HiCheckCircle>
                          </div>
                          <div className="col-span-7">5 User</div>
                          <div className="flex justify-center justify-items-center">
                            <HiCheckCircle className="text-[#7380EC] size-5"></HiCheckCircle>
                          </div>
                          <div className="col-span-7">1 Unit</div>
                          <div className="flex justify-center justify-items-center">
                            <HiCheckCircle className="text-[#7380EC] size-5"></HiCheckCircle>
                          </div>
                          <div className="col-span-7">
                            Konsolidasi<br></br>Fungsi untuk melihat laporan
                            keuangan yang ada pada unit untuk dilihat oleh pusat
                          </div>
                        </div>
                      </CardContent>
                      <CardFooter>
                        <Button className="bg-[var(--color-primary)]">
                          <a
                            href="https://api.whatsapp.com/send?phone=+6281322290809&amp;text=Halo%20CG%20Finance,%20saya%20tertarik%20dengan%20CG%20Finance%20Konsolidasi"
                            target="_blank"
                          >
                            Hubungi kami untuk penjelasan lebih lanjut
                          </a>
                        </Button>
                      </CardFooter>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </ScrollArea>
        </div>
      </div>
    </div>
  );
}
