import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loader2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import { API } from "../../helpers";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
import logoImgBlue from "../../assets/img/cg-finance-blue-2.png";
import logoImgSPIN from "../../assets/img/spin-international.png";
import background from "../../assets/img/bg-login.png";
import Swal from "sweetalert2";

async function loginUser(credentials) {
  return fetch(`${API}/auth/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
}

export default function Login() {
  const navigate = useNavigate();
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);

  const handleSubmit = async (e) => {
    setLoadingBtn(true);
    e.preventDefault();
    if (username == "" || password == "") {
      Swal.fire({
        title: "Error!",
        text: "Username dan Password harus diisi!",
        icon: "error",
      });
      setLoadingBtn(false);
      return;
    }
    const response = await loginUser({
      username,
      password,
    });

    if (response.code == 200) {
      if ("access_token" in response.data.tokens) {
        Swal.fire({
          title: "Berhasil",
          text: response.message,
          icon: "success",
          buttons: false,
          timer: 2000,
        }).then((value) => {
          localStorage.setItem(
            "accessToken",
            response.data.tokens["access_token"]
          );
          localStorage.setItem("user", JSON.stringify(response.data));
          window.location.href = "/home";
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: response.message,
          icon: "error",
        });
        setLoadingBtn(false);
      }
    } else {
      Swal.fire({
        title: "Error!",
        text: response.message,
        icon: "error",
      });
      setLoadingBtn(false);
    }
  };

  return (
    <div
      className="grid place-items-center h-screen"
      style={{
        backgroundImage: `url(${background})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100vw",
        height: "100vh",
      }}
    >
      <form onSubmit={handleSubmit}>
        <Card className="w-[400px]">
          <CardHeader className="flex justify-center items-center gap-3">
            <Avatar>
              <AvatarImage className="size-32" src={logoImgBlue} />
              <AvatarFallback>CGF</AvatarFallback>
            </Avatar>
            <CardTitle className="uppercase">Masuk Akun</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid w-full items-center gap-4">
              <div className="flex flex-col space-y-1.5">
                <Label htmlFor="email">Email *</Label>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  onChange={(e) => setUserName(e.target.value)}
                />
              </div>
              <div className="flex flex-col space-y-1.5">
                <Label htmlFor="email">Kata Sandi *</Label>
                <Input
                  iconRight={
                    <>
                      {showPassword ? (
                        <HiOutlineEye
                          onClick={() => setShowPassword(!showPassword)}
                          size={20}
                          className="absolute right-0 top-[0.65rem] mr-3 text-gray-500"
                        />
                      ) : (
                        <HiOutlineEyeOff
                          onClick={() => setShowPassword(!showPassword)}
                          size={20}
                          className="absolute right-0 top-[0.65rem] mr-3 text-gray-500"
                        />
                      )}
                    </>
                  }
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="flex flex-col items-end">
                <Button
                  type="button"
                  variant="link"
                  className="pr-0 text-[var(--color-primary)]"
                >
                  Lupa Password?
                </Button>
              </div>
            </div>
          </CardContent>
          <CardFooter className="flex flex-col justify-between">
            <div className="w-full">
              <Button
                type="submit"
                className="bg-[var(--color-primary)] w-full"
                disabled={loadingBtn ? true : false}
              >
                {
                  <>
                    {loadingBtn ? (
                      <>
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        <span>Loading...</span>
                      </>
                    ) : (
                      <>
                        <span>Masuk</span>
                      </>
                    )}
                  </>
                }
              </Button>
            </div>
            <div className="w-full text-center">
              Belum Punya Akun?
              <Button
                variant="link"
                className="pl-1 text-[var(--color-primary)]"
                onClick={() => navigate("/registration")}
              >
                Buat Akun
              </Button>
            </div>
          </CardFooter>
        </Card>
      </form>
      <div className="grid place-items-center">
        <Avatar className="mb-2">
          <AvatarImage className="w-24 h-10 mb-0" src={logoImgSPIN} />
          <AvatarFallback>SIT</AvatarFallback>
        </Avatar>
        <div className="text-xs">
          Ⓒ 2024 -
          <a
            className="pl-1 text-sm text-[var(--color-primary)]"
            href="https://spin-international.com/"
            target="_blank"
          >
            PT SPIN INTERNATIONAL INDONESIA
          </a>
        </div>
      </div>
    </div>
  );
}
