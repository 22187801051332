import React, { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Loader2 } from "lucide-react";
import { API } from "../../helpers";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from "@/components/ui/breadcrumb";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import { HiHome } from "react-icons/hi";
import { twMerge } from "tailwind-merge";
import Header from "@/components/Header";
import SideBar from "@/components/Sidebar";
import Swal from "sweetalert2";

// Function to fetch data asynchronously
const fetchData = async (url, accessToken) => {
  const response = await fetch(`${API}${url}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const data = await response.json();
  return data.data; // Returning the actual data
};

// Fetch company profile for initial form data
const fetchCompanyProfile = async (user, accessToken) => {
  const res = await fetch(`${API}/company/detail/${user.company_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return res.json();
};

// Function to update the company profile
async function profileCompany(dataRequest, user, accessToken) {
  return fetch(`${API}/company/create-update/${user.company_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(dataRequest),
  }).then((data) => data.json());
}

export default function Profile() {
  const [isActive, setActive] = useState(true);
  const handleSidebar = () => {
    setActive(!isActive);
  };

  // Consolidated localStorage access
  const [user, setUser] = useState(() => JSON.parse(localStorage.getItem("user")));
  const [accessToken, setAccessToken] = useState(() => localStorage.getItem("accessToken"));

  // Form state
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [address, setAddress] = useState("");
  const [province, setProvince] = useState("");
  const [subProvince, setSubProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [subDistrict, setSubDistrict] = useState("");
  const [loadingBtn, setLoadingBtn] = useState(false);

  // Track previous values to avoid unnecessary resets
  const [previousProvince, setPreviousProvince] = useState("");
  const [previousSubProvince, setPreviousSubProvince] = useState("");
  const [previousDistrict, setPreviousDistrict] = useState("");

  // Data for dropdowns
  const [dataProvince, setDataProvince] = useState([]);
  const [dataSubProvince, setDataSubProvince] = useState([]);
  const [dataDistrict, setDataDistrict] = useState([]);
  const [dataSubDistrict, setDataSubDistrict] = useState([]);

  // Fetch initial company profile
  useEffect(() => {
    const fetchInitialProfile = async () => {
      setLoadingBtn(true);
      try {
        const data = await fetchCompanyProfile(user, accessToken);
        const companyData = data.data;
        setName(companyData.name);
        setEmail(companyData.email);
        setPhone(companyData.phone);
        setCode(companyData.code);
        setAddress(companyData.address);
        setProvince(companyData.province);
        setSubProvince(companyData.sub_province);
        setDistrict(companyData.district);
        setSubDistrict(companyData.sub_district);
        setPreviousProvince(companyData.province);
        setPreviousSubProvince(companyData.sub_province);
        setPreviousDistrict(companyData.district);
      } catch (error) {
        console.error("Error fetching profile:", error);
      } finally {
        setLoadingBtn(false);
      }
    };

    fetchInitialProfile();
  }, [user, accessToken]);

  // Fetch chain logic for dependent dropdowns
  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        // Fetch provinces
        const provinceData = await fetchData("/params/province", accessToken);
        setDataProvince(provinceData);

        // Fetch sub_province after province is selected
        if (province) {
          const subProvinceData = await fetchData(`/params/sub-province/${province}`, accessToken);
          setDataSubProvince(subProvinceData);
        }

        // Fetch district after sub_province is selected
        if (subProvince) {
          const districtData = await fetchData(`/params/district/${subProvince}`, accessToken);
          setDataDistrict(districtData);
        }

        // Fetch sub_district after district is selected
        if (district) {
          const subDistrictData = await fetchData(`/params/sub-district/${district}`, accessToken);
          setDataSubDistrict(subDistrictData);
        }
      } catch (error) {
        console.error("Error fetching dropdown data:", error);
      }
    };

    fetchDropdownData();
  }, [province, subProvince, district, accessToken]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingBtn(true);

    const response = await profileCompany(
      {
        name,
        email,
        phone,
        code,
        address,
        province,
        sub_province: subProvince,
        district,
        sub_district: subDistrict,
      },
      user,
      accessToken
    );

    if (response.code === 200) {
      Swal.fire({
        title: "Berhasil",
        text: response.message,
        icon: "success",
        timer: 2000,
      }).then(() => {
        localStorage.setItem("user", JSON.stringify(response.data));
        setUser(response.data);
      });
    } else {
      Swal.fire({
        title: "Error!",
        text: response.message,
        icon: "error",
      });
    }

    setLoadingBtn(false);
  };

  return (
    <div className="layout">
      <Header />
      <div className="flex-1 h-[900px] relative">
        <SideBar />
        <div className={twMerge("content-in", isActive && "active")}>
          <ScrollArea className="rounded-md scroll-sidebar">
            <div className="p-0">
              <div className="h-auto">
                <Label className="text-2xl font-bold title-main">Profil Perusahaan</Label>
                <Breadcrumb>
                  <BreadcrumbList>
                    <BreadcrumbItem>
                      <BreadcrumbLink href="/home" className="flex gap-1 items-center">
                        <HiHome /> Beranda
                      </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />
                    <BreadcrumbItem>
                      <BreadcrumbPage>Profil Perusahaan</BreadcrumbPage>
                    </BreadcrumbItem>
                  </BreadcrumbList>
                </Breadcrumb>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="h-auto pt-4">
                  <div className="grid grid-cols-12 gap-5">
                    <div className="col-span-12 h-full">
                      <Card className="w-full h-full pt-5">
                        <CardContent>
                          <div className="grid grid-cols-12 w-full items-center gap-4">
                            <div className="col-span-12">
                              <div className="flex flex-col space-y-1.5">
                                <Label htmlFor="name">Nama Perusahaan *</Label>
                                <Input id="name" type="text" value={name} onChange={(e) => setName(e.target.value)} />
                              </div>
                            </div>
                            <div className="col-span-4">
                              <div className="flex flex-col space-y-1.5">
                                <Label htmlFor="code">Kode Perusahaan *</Label>
                                <Input id="code" type="text" value={code} onChange={(e) => setCode(e.target.value)} />
                              </div>
                            </div>
                            <div className="col-span-4">
                              <div className="flex flex-col space-y-1.5">
                                <Label htmlFor="email">Email Perusahaan</Label>
                                <Input id="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                              </div>
                            </div>
                            <div className="col-span-4">
                              <div className="flex flex-col space-y-1.5">
                                <Label htmlFor="phone">Telp Perusahaan</Label>
                                <Input id="phone" type="text" value={phone} onChange={(e) => setPhone(e.target.value)} />
                              </div>
                            </div>
                            <div className="col-span-12">
                              <div className="flex flex-col space-y-1.5">
                                <Label htmlFor="address">Alamat Perusahaan</Label>
                                <Textarea
                                  id="address"
                                  value={address}
                                  onChange={(e) => setAddress(e.target.value)}
                                  placeholder="Ketik alamat perusahaan anda disini."
                                />
                              </div>
                            </div>
                            <div className="col-span-3">
                              <div className="flex flex-col space-y-1.5">
                                <Label htmlFor="province">Propinsi</Label>
                                <Select id="province" onValueChange={setProvince} value={province}>
                                  <SelectTrigger className="w-full">
                                    <SelectValue placeholder="Pilih Propinsi" />
                                  </SelectTrigger>
                                  <SelectContent>
                                    <SelectGroup>
                                      {dataProvince.map((province) => (
                                        <SelectItem key={province.province} value={province.province}>
                                          {province.province}
                                        </SelectItem>
                                      ))}
                                    </SelectGroup>
                                  </SelectContent>
                                </Select>
                              </div>
                            </div>
                            <div className="col-span-3">
                              <div className="flex flex-col space-y-1.5">
                                <Label htmlFor="sub_province">Kota/Kabupaten</Label>
                                <Select id="sub_province" onValueChange={setSubProvince} value={subProvince}>
                                  <SelectTrigger className="w-full">
                                    <SelectValue placeholder="Pilih Kota/Kabupaten" />
                                  </SelectTrigger>
                                  <SelectContent>
                                    <SelectGroup>
                                      {dataSubProvince.map((subprovince) => (
                                        <SelectItem key={subprovince.sub_province} value={subprovince.sub_province}>
                                          {subprovince.sub_province}
                                        </SelectItem>
                                      ))}
                                    </SelectGroup>
                                  </SelectContent>
                                </Select>
                              </div>
                            </div>
                            <div className="col-span-3">
                              <div className="flex flex-col space-y-1.5">
                                <Label htmlFor="district">Kecamatan</Label>
                                <Select id="district" onValueChange={setDistrict} value={district}>
                                  <SelectTrigger className="w-full">
                                    <SelectValue placeholder="Pilih Kecamatan" />
                                  </SelectTrigger>
                                  <SelectContent>
                                    <SelectGroup>
                                      {dataDistrict.map((district) => (
                                        <SelectItem key={district.district} value={district.district}>
                                          {district.district}
                                        </SelectItem>
                                      ))}
                                    </SelectGroup>
                                  </SelectContent>
                                </Select>
                              </div>
                            </div>
                            <div className="col-span-3">
                              <div className="flex flex-col space-y-1.5">
                                <Label htmlFor="sub_district">Kelurahan/Desa</Label>
                                <Select id="sub_district" onValueChange={setSubDistrict} value={subDistrict}>
                                  <SelectTrigger className="w-full">
                                    <SelectValue placeholder="Pilih Kelurahan/Desa" />
                                  </SelectTrigger>
                                  <SelectContent>
                                    <SelectGroup>
                                      {dataSubDistrict.map((subDistrict) => (
                                        <SelectItem key={subDistrict.sub_district} value={subDistrict.sub_district}>
                                          {subDistrict.sub_district}
                                        </SelectItem>
                                      ))}
                                    </SelectGroup>
                                  </SelectContent>
                                </Select>
                              </div>
                            </div>
                          </div>
                        </CardContent>
                        <CardFooter className="flex">
                          <div className="w-[500px]">
                            <Button
                              type="submit"
                              className="bg-[var(--color-primary)] w-full"
                              disabled={loadingBtn}
                            >
                              {loadingBtn ? (
                                <>
                                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                  <span>Loading...</span>
                                </>
                              ) : (
                                <span>Simpan Data</span>
                              )}
                            </Button>
                          </div>
                        </CardFooter>
                      </Card>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </ScrollArea>
        </div>
      </div>
    </div>
  );
}
