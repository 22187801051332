import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./assets/css/index.css";
import Login from "./pages/login/index.jsx";
import Registration from "./pages/registration/index.jsx";
import Home from "./pages/home/index.jsx";
import Order from "./pages/order/index.jsx";
import Profile from "./pages/profile/index.jsx";
import ChangePassword from "./pages/changepassword/index.jsx";
import Billing from "./pages/billing/index.jsx";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/registration",
    element: <Registration />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/order",
    element: <Order />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/change-password",
    element: <ChangePassword />,
  },
  {
    path: "/billing",
    element: <Billing />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
