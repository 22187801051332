import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { ScrollArea } from "@/components/ui/scroll-area";
import Header from "@/components/Header";
import { AlertCircle } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  HiHome,
  HiCalendar,
  HiViewGridAdd,
  HiCurrencyDollar,
  HiDotsVertical,
} from "react-icons/hi";
import { twMerge } from "tailwind-merge";
import imgBanner1 from "../../assets/img/banner/1.png";
import SideBar from "../../components/Sidebar";

export default function Home() {
  const navigate = useNavigate();
  const [isActive, setActive] = useState(true);
  const handleSidebar = () => {
    setActive(!isActive);
  };
  const user = JSON.parse(localStorage.getItem("user"));

  const invoices = [];

  return (
    <div className="layout">
      <Header />
      <div className="flex-1 h-[900px] relative">
        <SideBar />
        <div className={twMerge("content-in", isActive && "active")}>
          <ScrollArea className="rounded-md scroll-sidebar">
            <div className="p-0">
              <div className="h-auto">
                <Label className="text-2xl font-bold title-main">
                  Dashboard
                </Label>
                <Breadcrumb>
                  <BreadcrumbList>
                    <BreadcrumbItem>
                      <BreadcrumbLink
                        href="/home"
                        className="flex gap-1 items-center"
                      >
                        <HiHome></HiHome> Beranda
                      </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />
                    <BreadcrumbItem>
                      <BreadcrumbPage>Dashboard</BreadcrumbPage>
                    </BreadcrumbItem>
                  </BreadcrumbList>
                </Breadcrumb>
              </div>
              <div className="h-auto pt-4">
                <div className="grid grid-cols-12 gap-5">
                  {user.company_id <= 0 && (
                    <div className="col-span-12 px-3">
                      <Alert variant="destructive">
                        <AlertCircle className="h-4 w-4" />
                        <AlertTitle>Perhatian!</AlertTitle>
                        <AlertDescription>
                          Anda belum mengisi profil perusahaan, silahkan isi
                          profil perusahaan terlebih dahulu.{" "}
                          <Button
                            className="bg-[var(--color-primary)]"
                            onClick={() => navigate("/profile")}
                            size="sm"
                          >
                            Isi disini
                          </Button>
                        </AlertDescription>
                      </Alert>
                    </div>
                  )}
                  <div className="col-span-8 h-full">
                    <Carousel className="w-[88%] ml-12">
                      <CarouselContent>
                        {Array.from({ length: 5 }).map((_, index) => (
                          <CarouselItem key={index}>
                            <div className="p-1">
                              <img src={imgBanner1} className="w-full"></img>
                            </div>
                          </CarouselItem>
                        ))}
                      </CarouselContent>
                      <CarouselPrevious />
                      <CarouselNext />
                    </Carousel>
                  </div>
                  <div className="col-span-4 h-full">
                    <Card className="w-full h-full">
                      <CardHeader>
                        <CardTitle className="flex justify-between items-center">
                          <span className="color-secondary">Pengumuman</span>
                          <Button
                            className="bg-blue-300 py-0 btnin color-primary"
                            variant="outline"
                          >
                            Lihat Semua
                          </Button>
                        </CardTitle>
                      </CardHeader>
                      <CardContent>
                        <div className="pb-2">
                          <div className="text-md font-semibold color-secondary">
                            Judul Pengumuman 1
                          </div>
                          <div className="text-xs color-third">
                            We can only copy/paste elements (or group of
                            elements) in the same page, which is quite limited.
                          </div>
                          <div className="text-xs color-third mt-2">
                            3 May 2024
                          </div>
                        </div>
                        <div className="pb-2">
                          <div className="text-md font-semibold color-secondary">
                            Judul Pengumuman 1
                          </div>
                          <div className="text-xs color-third">
                            We can only copy/paste elements (or group of
                            elements) in the same page, which is quite limited.
                          </div>
                          <div className="text-xs color-third mt-2">
                            3 May 2024
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </div>
                  <div className="col-span-8 h-full pt-4">
                    <div className="grid grid-cols-12 gap-4">
                      <div className="col-span-4">
                        <Card>
                          <CardContent className="p-3 flex items-center">
                            <Avatar>
                              <AvatarFallback className="size-14 bg-in">
                                <HiCalendar className="size-8 color-primary" />
                              </AvatarFallback>
                            </Avatar>
                            <div className="pl-5">
                              <div className="text-sm color-third">
                                Masa Berlaku Akun
                              </div>
                              <div className="flex items-baseline">
                                <div className="text-lg font-bold color-secondary">
                                  0
                                </div>
                                <div className="text-sm ml-1 color-third">
                                  hari
                                </div>
                              </div>
                              <div className="text-sm color-third">-</div>
                            </div>
                          </CardContent>
                        </Card>
                      </div>
                      <div className="col-span-4">
                        <Card className="h-full">
                          <CardContent className="p-3 flex items-center">
                            <Avatar>
                              <AvatarFallback className="size-14 bg-in">
                                <HiViewGridAdd className="size-8 color-primary" />
                              </AvatarFallback>
                            </Avatar>
                            <div className="pl-5">
                              <div className="text-sm color-third">
                                Layanan Aktif
                              </div>
                              <div className="flex items-baseline">
                                <div className="text-lg font-bold color-secondary">
                                  0
                                </div>
                                <div className="text-sm ml-1 color-third">
                                  produk aktif
                                </div>
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      </div>
                      <div className="col-span-4">
                        <Card className="h-full">
                          <CardContent className="p-3 flex items-center">
                            <Avatar>
                              <AvatarFallback className="size-14 bg-in">
                                <HiCurrencyDollar className="size-8 color-danger" />
                              </AvatarFallback>
                            </Avatar>
                            <div className="pl-5">
                              <div className="text-sm color-third">
                                Invoice Unpaid
                              </div>
                              <div className="flex items-baseline">
                                <div className="text-lg font-bold color-secondary">
                                  0
                                </div>
                                <div className="text-sm ml-1 color-third">
                                  pending
                                </div>
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      </div>
                    </div>
                    <div className="mt-5">
                      <Card className="h-full">
                        <CardTitle className="flex justify-between items-center p-4">
                          <span className="color-secondary">
                            Pending Invoice
                          </span>
                          <Button
                            className="bg-blue-300 py-0 btnin-icon"
                            variant="outline"
                            size="icon"
                          >
                            <HiDotsVertical className="mr-2 h-4 w-4" />
                          </Button>
                        </CardTitle>
                        <CardContent className="p-3 flex items-center">
                          <Table>
                            <TableHeader>
                              <TableRow>
                                <TableHead className="w-[100px]">
                                  Invoice
                                </TableHead>
                                <TableHead>Status</TableHead>
                                <TableHead>Method</TableHead>
                                <TableHead className="text-right">
                                  Amount {invoices.length}
                                </TableHead>
                              </TableRow>
                            </TableHeader>
                            <TableBody>
                              {invoices.map((invoice) => (
                                <TableRow key={invoice.invoice}>
                                  <TableCell className="font-medium">
                                    {invoice.invoice}
                                  </TableCell>
                                  <TableCell>{invoice.paymentStatus}</TableCell>
                                  <TableCell>{invoice.paymentMethod}</TableCell>
                                  <TableCell className="text-right">
                                    {invoice.totalAmount}
                                  </TableCell>
                                </TableRow>
                              ))}
                              {invoices.length <= 0 && (
                                <TableRow>
                                  <TableCell
                                    colSpan={4}
                                    className="text-center"
                                  >
                                    Tidak ada data
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </CardContent>
                      </Card>
                    </div>
                  </div>
                  <div className="col-span-4 h-full">
                    <Card className="w-full h-full">
                      <CardHeader>
                        <CardTitle className="flex justify-between items-center">
                          <span className="color-secondary">Manual Book</span>
                          <Button
                            className="bg-blue-300 py-0 btnin color-primary"
                            variant="outline"
                          >
                            Lihat Semua
                          </Button>
                        </CardTitle>
                      </CardHeader>
                      <CardContent>
                        <div className="pb-2">
                          <div className="text-md font-semibold color-secondary">
                            Judul Pengumuman 1
                          </div>
                          <div className="text-xs color-third">
                            We can only copy/paste elements (or group of
                            elements) in the same page, which is quite limited.
                          </div>
                        </div>
                        <div className="pb-2">
                          <div className="text-md font-semibold color-secondary">
                            Judul Pengumuman 1
                          </div>
                          <div className="text-xs color-third">
                            We can only copy/paste elements (or group of
                            elements) in the same page, which is quite limited.
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </ScrollArea>
        </div>
      </div>
    </div>
  );
}
