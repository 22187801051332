import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loader2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import { API } from "../../helpers";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/components/ui/resizable";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Switch } from "@/components/ui/switch";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
import logoWA from "../../assets/img/wa.png";
import background from "../../assets/img/bg-login-1.png";
import logoImgBlue from "../../assets/img/cg-finance-blue-2.png";
import Swal from "sweetalert2";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";

async function registrationUser(credentials) {
  return fetch(`${API}/auth/registration`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
}

export default function Login() {
  const navigate = useNavigate();
  const [company, setCompany] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [checkAgree, setAgree] = useState();
  const [loadingBtn, setLoadingBtn] = useState(false);

  const handleSubmit = async (e) => {
    setLoadingBtn(true);
    e.preventDefault();

    if (checkAgree != "on") {
      Swal.fire({
        title: "Warning!",
        text: "Silahkan centang saya telah membaca Syarat dan Ketentuan, terlebih dahulu!!",
        icon: "warning",
      });

      setLoadingBtn(false);
    } else {
      const response = await registrationUser({
        name: name,
        company: company,
        email: email,
        phone: phone,
        password: password,
        confirm_password: confirmPassword,
      });
      if (response.code == 200) {
        Swal.fire({
          title: "Berhasil",
          text: response.message,
          icon: "success",
          buttons: false,
          timer: 2000,
        }).then((value) => {
          window.location.href = "/";
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: response.message,
          icon: "error",
        });
      }
    }
  };

  return (
    <div
      className="grid place-items-center h-screen"
      style={{
        backgroundImage: `url(${background})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100vw",
        height: "100vh",
      }}
    >
      <ResizablePanelGroup direction="horizontal" className="max-w-[70%]">
        <ResizablePanel defaultSize={40}>
          <div className="flex h-full justify-center items-center">
            <div className="inline-grid grid-cols-6 gap-4 w-full">
              <div className="col-span-6 justify-center flex">
                <Avatar>
                  <AvatarImage className="size-36" src={logoImgBlue} />
                  <AvatarFallback>CGF</AvatarFallback>
                </Avatar>
              </div>
              <div className="flex justify-center items-center">
                <Switch id="airplane-mode" />
              </div>
              <div className="text-left col-span-5">
                <Label>
                  Coba Gratis<br></br>
                  <span className="text-gray-400 font-normal">
                    Coba aplikasi CG Finance gratis 7 hari
                  </span>
                </Label>
              </div>
              <div className="flex justify-center items-center">
                <a
                  href="https://api.whatsapp.com/send?phone=+6281322290809&amp;text=Halo%20CG%20Finance,%20saya%20tertarik%20dengan%20CG%20Finance%20Konsolidasi"
                  target="_blank"
                >
                  <Avatar>
                    <AvatarImage className="size-8" src={logoWA} />
                    <AvatarFallback>CGF</AvatarFallback>
                  </Avatar>
                </a>
              </div>
              <div className="text-left col-span-5">
                <Label>
                  Konsolidasi Laporan Keuangan Grup<br></br>
                  <span className="text-gray-400 font-normal">
                    Berbicara dengan Customer Service kami untuk mendapatkan
                    penjelasan lebih lanjut
                  </span>
                </Label>
              </div>
            </div>
          </div>
        </ResizablePanel>
        <ResizableHandle />
        <ResizablePanel defaultSize={60} className="items-center">
          <ScrollArea className="rounded-md scroll-sidebar">
            <div className="flex justify-center items-center w-full h-full">
              <div className="w-full mx-5">
                <form onSubmit={handleSubmit}>
                  <div className="text-center mb-10">
                    <Label className="grid mb-2 text-3xl font-bold mt-5">
                      Daftar Akun
                    </Label>
                    <Label className="grid text-gray-400 font-normal">
                      Daftarkan akun kamu disini
                    </Label>
                  </div>
                  <div className="grid grid-cols-2 w-full items-center gap-3">
                    <div className="flex flex-col space-y-1.5">
                      <Label htmlFor="name">Nama *</Label>
                      <Input
                        id="name"
                        name="name"
                        type="text"
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="flex flex-col space-y-1.5">
                      <Label htmlFor="name">Nama Perusahaan *</Label>
                      <Input
                        id="company"
                        name="company"
                        type="text"
                        onChange={(e) => setCompany(e.target.value)}
                      />
                    </div>
                    <div className="flex flex-col col-span-2 space-y-1.5">
                      <Label htmlFor="email">Email *</Label>
                      <Input
                        id="email"
                        name="email"
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <Label className="text-xs font-light text-gray-400">
                        * Email ini akan digunakan untuk melakukan aktivasi akun
                        dan pembayaran
                      </Label>
                    </div>
                    <div className="flex flex-col col-span-2 space-y-1.5">
                      <Label htmlFor="phone">Nomor HP *</Label>
                      <Input
                        id="phone"
                        name="phone"
                        type="text"
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                    <div className="flex flex-col space-y-1.5">
                      <Label htmlFor="password">Kata Sandi *</Label>
                      <Input
                        iconRight={
                          <>
                            {showPassword ? (
                              <HiOutlineEye
                                onClick={() => setShowPassword(!showPassword)}
                                size={20}
                                className="absolute right-0 top-[0.65rem] mr-3 text-gray-500"
                              />
                            ) : (
                              <HiOutlineEyeOff
                                onClick={() => setShowPassword(!showPassword)}
                                size={20}
                                className="absolute right-0 top-[0.65rem] mr-3 text-gray-500"
                              />
                            )}
                          </>
                        }
                        id="password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div className="flex flex-col space-y-1.5">
                      <Label htmlFor="confirm">Konfirmasi Kata Sandi *</Label>
                      <Input
                        iconRight={
                          <>
                            {showConfirmPassword ? (
                              <HiOutlineEye
                                onClick={() =>
                                  setShowConfirmPassword(!showConfirmPassword)
                                }
                                size={20}
                                className="absolute right-0 top-[0.65rem] mr-3 text-gray-500"
                              />
                            ) : (
                              <HiOutlineEyeOff
                                onClick={() =>
                                  setShowConfirmPassword(!showConfirmPassword)
                                }
                                size={20}
                                className="absolute right-0 top-[0.65rem] mr-3 text-gray-500"
                              />
                            )}
                          </>
                        }
                        id="confirm_password"
                        name="confirm_password"
                        type={showConfirmPassword ? "text" : "password"}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </div>
                    <div className="flex justify-center col-span-2 items-center w-full">
                      <div className="flex items-center flex-wrap">
                        <Input
                          id="agree"
                          type="checkbox"
                          onChange={(e) => setAgree(e.target.value)}
                        />
                        <Label className="pl-2 whitespace-nowrap">
                          Saya telah membaca
                        </Label>
                        <Dialog>
                          <DialogTrigger asChild>
                            <Button
                              variant="link"
                              className="px-1 mx-0 text-[var(--color-primary)] whitespace-nowrap"
                            >
                              Syarat & Ketentuan
                            </Button>
                          </DialogTrigger>
                          <DialogContent className="sm:max-w-[425px] bg-white">
                            <DialogHeader>
                              <DialogTitle>Syarat & Ketentuan</DialogTitle>
                            </DialogHeader>
                            <div className="grid gap-4 py-4">
                              <div className="grid grid-cols-1 items-center gap-4">
                                <ul className="list-disc mx-4">
                                  <li>Harga sudah termasuk PPN 11%.</li>
                                  <li>
                                    Pembayaran Layanan Bulanan dilakukan setiap
                                    bulan dimuka.
                                  </li>
                                  <li>
                                    Layanan Aplikasi sudah termasuk penyediaan
                                    server dan back-up data.
                                  </li>
                                  <li>
                                    Dukungan Teknis dberikan setiap hari dan jam
                                    kerja untuk kondisi normal, dan tetap akan
                                    diberikan dukungan teknis diluar jam kerja
                                    untuk kondisi mendesak.
                                  </li>
                                  <li>
                                    Biaya Layanan tidak termasuk biaya pelatihan
                                    diluar Jabodetabek (tiket dan akomodasi).
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <DialogFooter>
                              <DialogClose asChild>
                                <Button type="button" variant="secondary">
                                  Tutup
                                </Button>
                              </DialogClose>
                            </DialogFooter>
                          </DialogContent>
                        </Dialog>
                        <Label className="whitespace-nowrap">
                          yang berlaku
                        </Label>
                      </div>
                    </div>
                    <div className="flex justify-center col-span-2 items-center w-full">
                      <div className="w-full">
                        <Button
                          type="submit"
                          className="bg-[var(--color-primary)] w-full"
                          disabled={loadingBtn ? true : false}
                        >
                          {
                            <>
                              {loadingBtn ? (
                                <>
                                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                  <span>Loading...</span>
                                </>
                              ) : (
                                <>
                                  <span>Daftar Akun</span>
                                </>
                              )}
                            </>
                          }
                        </Button>
                      </div>
                    </div>
                    <div className="flex justify-center col-span-2 items-center w-full">
                      <div className="w-full text-center">
                        Sudah Punya Akun?
                        <Button
                          variant="link"
                          className="pl-1 text-[var(--color-primary)]"
                          onClick={() => navigate("/")}
                        >
                          Masuk Akun
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </ScrollArea>
        </ResizablePanel>
      </ResizablePanelGroup>
    </div>
  );
}
