import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import { HiHome } from "react-icons/hi";
import { twMerge } from "tailwind-merge";
import Header from "@/components/Header";
import SideBar from "@/components/Sidebar";

export default function ChangePassword() {
  const navigate = useNavigate();
  const [isActive, setActive] = useState(true);
  const handleSidebar = () => {
    setActive(!isActive);
  };

  return (
    <div className="layout">
      <Header />
      <div className="flex-1 h-[900px] relative">
        <SideBar />
        <div className={twMerge("content-in", isActive && "active")}>
          <ScrollArea className="rounded-md scroll-sidebar">
            <div className="p-0">
              <div className="h-auto">
                <Label className="text-2xl font-bold title-main">
                  Change Password
                </Label>
                <Breadcrumb>
                  <BreadcrumbList>
                    <BreadcrumbItem>
                      <BreadcrumbLink
                        href="/home"
                        className="flex gap-1 items-center"
                      >
                        <HiHome></HiHome> Beranda
                      </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />
                    <BreadcrumbItem>
                      <BreadcrumbPage>Change Password</BreadcrumbPage>
                    </BreadcrumbItem>
                  </BreadcrumbList>
                </Breadcrumb>
              </div>
              <form>
                <div className="h-auto pt-4">
                  <div className="grid grid-cols-12 gap-5">
                    <div className="col-span-12 h-full">
                      <Card className="w-full h-full pt-5">
                        <CardContent>
                          <div className="grid grid-cols-12 w-full items-center gap-4">
                            <div className="col-span-7">
                              <div className="flex flex-col space-y-1.5">
                                <Label htmlFor="name">Current Password *</Label>
                                <Input id="name" type="password" />
                              </div>
                            </div>
                            <div className="col-span-7">
                              <div className="flex flex-col space-y-1.5">
                                <Label htmlFor="name">New Password *</Label>
                                <Input id="name" type="password" />
                              </div>
                            </div>
                            <div className="col-span-7">
                              <div className="flex flex-col space-y-1.5">
                                <Label htmlFor="name">
                                  Confirm New Password *
                                </Label>
                                <Input id="name" type="password" />
                              </div>
                            </div>
                          </div>
                        </CardContent>
                        <CardFooter className="flex">
                          <div className="w-[500px]">
                            <Button
                              className="bg-[var(--color-primary)] w-full"
                              onClick={() => navigate("/home")}
                            >
                              Simpan Data
                            </Button>
                          </div>
                        </CardFooter>
                      </Card>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </ScrollArea>
        </div>
      </div>
    </div>
  );
}
