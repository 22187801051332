import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { HiMenu, HiCog, HiUser, HiOutlineLogout, HiKey } from "react-icons/hi";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

export default function Header() {
  const navigate = useNavigate();
  const handleSidebar = () => {
    setActive(!isActive);
  };
  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    window.location.href = "/";
  };

  return (
    <div className="header">
      <div className="w-[228px] p-3 flex justify-between items-center">
        <Label className="text-3xl/[20px] font-extrabold title-main">
          ClientZone
        </Label>
      </div>
      <div className="flex-1 flex items-center">
        <Button
          className="btnin-icon bg-blue-300"
          variant="outline"
          size="icon"
          onClick={handleSidebar}
        >
          <HiMenu className="h-5 w-5" />
        </Button>
      </div>
      <div className="w-[228px] flex items-center p-8 justify-end">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="outline"
              className="btnin-icon bg-blue-300"
              size="icon"
            >
              <HiCog className="h-5 w-5" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56">
            <DropdownMenuLabel>My Account</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuGroup>
              <DropdownMenuItem onClick={() => navigate("/profile")}>
                <HiUser className="mr-2 h-4 w-4" />
                <span>Profil Perusahaan</span>
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => navigate("/change-password")}>
                <HiKey className="mr-2 h-4 w-4" />
                <span>Ganti Kata Sandi</span>
              </DropdownMenuItem>
              {/* <DropdownMenuItem>
                <HiCog className="mr-2 h-4 w-4" />
                <span>Pengaturan</span>
              </DropdownMenuItem> */}
            </DropdownMenuGroup>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={handleLogout}>
              <HiOutlineLogout className="mr-2 h-4 w-4" />
              <span>Keluar</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
}
